import React from 'react'
import classes from './Brokers.css'

const brokers = ()=>{
    return (
        <div className={classes.Brokers}>
        

            <div><h4>Eon Lew</h4>
            <p>Eon@districtrealtygroup.com</p>
            <p> 818-395-8053</p></div>


        </div>
    )
}

export default brokers;
