import React from 'react';
import classes from './ContactUs.css';
import  MapContainer  from '../MapContainer/MapContainer'
import ContactForm from './ContactForm/ContactForm'

const contactUs = () =>{


    return(
    <div className={classes.Background}>
       <div className={classes.Contact}>
        <div>
        <h5>Ai Management</h5>
        <span>Joshua Elnas (Jelnas@AiManagementCo.com)</span>
        <span>Property Manager</span>
        <span>420 E. 3rd St. Ste 704</span>
        <span>Los Angeles, CA 90013</span>
        <span>213-379-4418</span>
       <p></p>
        <h5>District Realty Group</h5>
        <span>Eon Lew (Eon@districtrealtygroup.com)</span>
        <span>4334 W. Sunset Blvd.</span>
        <span>Los Angeles, CA 90029</span>
        <span>818-395-8053</span>

        </div>
        <div>
        <ContactForm/>

        </div>
        </div>
        <br/>
        <br/>
        <MapContainer/>
        </div>
        )

}


export default contactUs;
